
.footer {
    background-color: #ffffff;
    /* padding: 20px; */
    text-align: center;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer-content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .footer-logo img {
    width: 200px; /* Adjust the logo size as needed */
    margin-bottom: 20px;
    padding-top: 40px;
  }
  
  .footer-buttons {
    margin-bottom: 20px;
    display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center;
  margin-bottom: 20px;
  }
  
  .footer-btn {
    background-color: transparent;
    color: #000000;
    border: none;
    margin: 0 10px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s;
  }
  
  /* .footer-btn:hover {
    background-color: #1F59DA;
    border-radius: 5px;
  } */
  
  .download-btn {
    background-color: #1F59DA;
    color: #ffffff;
    border: none;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 12px;
    transition: background-color 0.3s;
  }
  
  .download-btn:hover {
    background-color: #15203D;
  }
  
  .footer-copyright {
    font-size: 14px;
    margin-top: 20px;
    color: #949494;
    
  }
  
  a{
    text-decoration: none;
    color: #000000;

  }
  /* a:hover{
    background-color: #1F59DA;
    color: #ffffff;
  } */
  
  .footer-copyright{
    border-top: 1px solid #949494;
  }

  @media (min-width: 769px) {
    
    .footer-content {
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
  }
  .footer-buttons{
    flex-direction: row;
    
   
    justify-content: center;

  }
  
  
    
  }