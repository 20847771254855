/* TeamSection.css */
.teamSection {
    padding: 20px;
}
* {
    box-sizing: border-box;
}

@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
  }
  
  @property --gradX {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
  }
  
  @property --gradY {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
  }

  :root {
	--d: 3500ms;
	--angle: 0deg; 
	--gradX: 50%;
	--gradY: 0%;
	--c1: #1F59DA; 
	--c2: rgba(168, 239, 255, 0.1);
}


.teamTitle {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #1F59DA;
    margin-bottom: 10px;
}

.tagline {
    text-align: center;
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 20px;
}

.teamGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}

.teamMember {
	position: relative;
	padding: 20px;
	text-align: center;
	box-sizing: border-box;
	border: 5px solid transparent;
	border-radius: 8px;
	overflow: hidden;
	background: white;
	opacity: 0;
	transform: translateY(20px);
	transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 0;
}

.teamMember::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 5px solid transparent;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
    animation: borderRotate var(--d) linear infinite forwards;
	z-index: 1;
}

@keyframes borderRotate {
    0% {
        --angle: 0deg;
    }
    100% {
        --angle: 360deg;
    }
}


.teamMember.visible {
    opacity: 1;
    transform: translateY(0);
}

.teamImage {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.teamImage:hover {
    transform: scale(1.05);
}

.teamName {
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.teamDesignation {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #565656;
    margin-bottom: 10px;
}

.linkedinLink {
    font-family: 'Outfit', sans-serif;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #1F59DA;
    z-index: 1; /* Ensures the link is on top */
    position: relative; /* Proper stacking */
}

.linkedinIcon {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}
.tagline{
    font-size: 20px !important;
}
.teamTitle{
    padding-top: 30px;

}

.githubLink {
    font-family: 'Outfit', sans-serif;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #1F59DA;
    z-index: 1;
    position: relative;
    margin-top: 5px;
}

.githubIcon {
    width: 10px;
    height: 10px;
    margin-left: 5px;
}

@media (min-width: 769px) {
    
    .teamGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* Define 4 equal columns */
        gap: 20px; /* Adjust gap between items */
    }
    
    .teamSection{
        padding-left: 8%;
        padding-right: 8%;
    }
    
    
    .teamImage {
        width: 75%;
        
        
    }

    .linkedinLink {
        font-size: 12px;
    }
    
    .githubLink {
        font-size: 12px;
    }
    
   
}