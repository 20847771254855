/* Common Styles */
.section {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden; /* Ensure no horizontal scrolling */
}

.title {
    font-family: 'Outfit', sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    color: #1F59DA;
    padding-top: 20px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
}

.gridItem {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    perspective: 1000px; /* Needed for 3D transform effect */
    position: relative;
    height: 200px; 
}

.gridImg {
    max-width: 100%; /* Ensure image fits container */
    height: auto; /* Maintain aspect ratio */
    padding-bottom: 1rem;
}

.flipCard {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    transform: rotateY(180deg); /* Show back side by default */
}

.flipCardInner {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flipCardFront {
    background-color: #1F59DA;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: rotateY(180deg); /* Hide the front side by default */
    border-radius: 20px;
    font-weight: 500;
    font-size: 20px;
    padding: 10px; /* Padding for better spacing on desktop */
}

.flipCardBack {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding: 10px; /* Padding for better spacing on desktop */
}

.flipped .flipCard {
    transform: rotateY(0deg); /* Show the front side on scroll */
}

#feat {
    padding-top: 30px;
}



@media (min-width: 769px) {
    .title {
        font-size: 32px;
        padding-top: 30px;
    }

    .gridContainer {
        grid-template-columns: repeat(3, 1fr); /* Three columns for desktop view */
        gap: 30px; /* Increase gap for better spacing */
    }
    #setting{
        display: grid;
        grid-template-columns: repeat(3, 1fr) ;
        /* gap: 40px; */
        padding-left: 8%;
        padding-right:8%;

    }

    .gridItem {
        /* display: grid;
        grid-template-columns: repeat(3, 1fr) ; */
       height: 250px; /* Adjust height for larger cards */
      /* Increase height for larger cards */
       padding-left: 10px;
       padding-right: 10px;
       
   
    }
    .gridImg{
        padding-bottom:0;
    }

    


    .flipCardFront, .flipCardBack {
        font-size: 24px; /* Larger text for desktop */
    }
}