/* HeroSection.css */

.hero-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #ffffff;
  }
  
  .hero-content {
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  .hero-content.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  .hero-heading {
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 8px;
    text-align: center; 
    padding-top: 60px;
  }
  
  .hero-subheadline {
    font-family: 'Outfit', sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 16px;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .hero-buttons {
    display: flex;
    flex-direction: column; 
    gap: 0.5rem; 
    margin-bottom: 1rem;
  }
  
  .hero-button {
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 0px;
  }
  
  .hero-button img {
    width: 250px;
    height: 48px;
  }
  
  .hero-img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .hero-video {
    max-width: 100%;
    height: auto;
}
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (min-width: 769px) {
    .hero-heading{
      font-weight: 600;
    font-size: 58px;

    }
    .hero-subheadline{
      font-size: 24px;
      margin-top: 10px;

    }
    .hero-buttons {
      display: flex;
      flex-direction: row; 
      gap: 0.5rem; 
      margin-bottom: 1rem;
    }
    .hero-img{
      width: 70%;
      
    }
    
  }